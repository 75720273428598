import React, { useLayoutEffect } from 'react';
import ReactFullpage from '@fullpage/react-fullpage';
import { graphql } from 'gatsby';
import { Layout, FullpageSection, Seo } from '../../shared/ui';
import { useAnimation } from '../../shared/hooks';

const OriginalDevelopment: React.FC = (props: any) => {
  const data =
    props.data.allContentfulOriginalDevelopmentList.nodes[0].development;
  const titles = data.map((brand) => brand.title);
  const slugs = data.map((brand) => brand.slug);

  useLayoutEffect(() => {
    document.title = 'Original Development';
    console.log('original');
    setTimeout(() => {
      document.documentElement.scrollTo(0, 0);
    }, 100);
  }, [data]);

  // useEffect(() => {
  //   if (typeof window !== 'undefined') {
  //     const playingVideos =
  //       document.querySelectorAll<HTMLVideoElement>('.playing-video') || null;

  //     playingVideos.forEach((vid) => {
  //       vid?.addEventListener('canplay', () => vid.play());
  //     });

  //     return () => {
  //       playingVideos.forEach((vid) => {
  //         vid?.removeEventListener('canplay', () => vid.play());
  //       });
  //     };
  //   }
  //   return () => {};
  // }, []);

  const { onLeave } = useAnimation();

  return (
    <Layout transparentNav topMargin={false}>
      <Seo title="Original Development - Bolt Tv" />
      <ReactFullpage
        anchors={slugs}
        navigation
        lazyLoading
        onLeave={onLeave}
        navigationTooltips={titles}
        scrollingSpeed={400}
        credits={{
          enabled: true,
          label: 'Original Development',
          position: 'right',
        }}
        render={() => (
          <ReactFullpage.Wrapper>
            {data?.map((brand) => (
              <FullpageSection
                key={brand.id}
                promoVideo={brand.videoPromo.url}
                director=""
                category={brand.category}
                slug={brand.slug}
                title={brand.title}
                subtitle={brand.subtitle}
                logoUrl={brand.logo?.url}
              />
            ))}
          </ReactFullpage.Wrapper>
        )}
      />
    </Layout>
  );
};

export const query = graphql`
  query QueryDevelopment {
    allContentfulOriginalDevelopmentList {
      nodes {
        development {
          title
          slug
          category
          subtitle
          videoPromo {
            url
          }
          logo {
            url
          }
          director
          mainImage {
            url
            title
          }
          id
        }
      }
    }
  }
`;

export default OriginalDevelopment;
